import { encodeCompositeMetadata, encodeRoute, WellKnownMimeType } from "rsocket-composite-metadata";
import { RSocketConnector } from "rsocket-core";
import { WebsocketClientTransport } from "rsocket-websocket-client";
const MAX_INT = 2147483647;
// TODO: Investigate handling server disconnects
export default class StreamClient {
    connector;
    rsocket;
    constructor() {
        this.connector = new RSocketConnector({
            setup: {
                dataMimeType: "application/json",
                metadataMimeType: "message/x.rsocket.composite-metadata.v0"
            },
            transport: new WebsocketClientTransport({
                url: `${window.origin.replace("http", "ws")}/notifications`,
                wsCreator: (url) => new WebSocket(url),
            }),
        });
    }
    async connect() {
        this.rsocket = await this.connector.connect();
    }
    isConnected() {
        return this.rsocket != null;
    }
    requestStream(data, route, onMessage) {
        const metadata = new Map([[WellKnownMimeType.MESSAGE_RSOCKET_ROUTING, encodeRoute(route)]]);
        let cancellable = null;
        const promise = new Promise((resolve, reject) => {
            cancellable = this.rsocket.requestStream({
                data,
                metadata: encodeCompositeMetadata(metadata)
            }, MAX_INT, {
                onNext({ data }) {
                    onMessage(JSON.parse(data.toString("utf-8")));
                },
                onError(e) {
                    reject(e);
                },
                onComplete() {
                    resolve(undefined);
                },
                onExtension() {
                    throw new Error("Not implemented.");
                }
            });
        });
        return [promise, cancellable];
    }
}
