import StreamClient from './StreamClient';
import { dispatchAgentAssistEvent as agentAssist } from './DispatchAgentAssistEvent';
export class UiConnectorClient {
    streamClient = new StreamClient();
    streams = new Map();
    async startConversation(conversationName) {
        if (!this.streamClient.isConnected()) {
            await this.streamClient.connect();
        }
        const conversationId = conversationName.split("/").pop();
        const route = `conversation.${conversationId}`;
        const [promise, cancellable] = this.streamClient.requestStream(null, route, m => this.process(m));
        this.streams.set(conversationName, cancellable);
        agentAssist("conversation-initialization-requested", { detail: { conversationName } });
        await promise;
    }
    async completeConversation(conversationName) {
        agentAssist("conversation-completed", { detail: { conversationName } });
        if (this.streams.has(conversationName)) {
            this.streams.get(conversationName).cancel();
        }
    }
    process(message) {
        if (message.type == "NEW_MESSAGE") {
            agentAssist("new-message-received", { detail: message.newMessagePayload });
        }
        else if (message.type == "CONVERSATION_STARTED") {
            agentAssist("conversation-initialization-requested", { detail: { conversationName: message.conversation } });
        }
        else if (message.type == "CONVERSATION_ENDED") {
            agentAssist("conversation-completed", {});
        }
        else {
            agentAssist("analyze-content-response-received", {
                detail: {
                    conversationName: message.conversation,
                    payload: {
                        response: {
                            humanAgentSuggestionResults: message.suggestionResults
                        }
                    }
                }
            });
        }
    }
}
