import { ConversationBroadcastClient } from '../common/ConversationChannel';
import { BroadcastProtocolImpl, BroadcastChannelImpl } from '../common/BroadcastProtocol';
import { waitForDocumentLoad } from '../common/Util';
import { UiConnectorClient } from './UiConnectorClient';
async function getConfig() {
    const resp = await fetch("/config");
    return await resp.json();
}
function initializeModules({ projectId }) {
    const attributes = [
        ["features", "SMART_REPLY,ARTICLE_SUGGESTION,CONVERSATION_SUMMARIZATION,FAQ"],
        ["conversation-profile", `projects/${projectId}/conversationProfiles/THIS_PART_DOESNT_MATTER`],
        ["agent-desktop", "Custom"],
        ["channel", "chat"],
        ["custom-api-endpoint", `${window.origin}/dialogflow`],
    ];
    const el = document.createElement("agent-assist-ui-modules");
    for (const [key, value] of attributes) {
        el.setAttribute(key, value);
    }
    document.getElementById("main")?.appendChild(el);
}
(async () => {
    const [{ projectId }, _] = await Promise.all([getConfig(), waitForDocumentLoad()]);
    initializeModules({ projectId });
    const connector = new UiConnectorClient();
    const channel = new BroadcastChannelImpl("ui-connector-client-integration");
    const proto = new BroadcastProtocolImpl(channel);
    const client = new ConversationBroadcastClient(proto);
    client.onConversationStarted().subscribe(async (v) => await connector.startConversation(v.conversationName));
    client.onConversationCompleted().subscribe(v => connector.completeConversation(v.conversationName));
})();
