import { fromEvent, map } from "rxjs";
export var BroadcastType;
(function (BroadcastType) {
    BroadcastType["BroadcastRequest"] = "broadcast-request";
    BroadcastType["BroadcastResponse"] = "broadcast-response";
    BroadcastType["Broadcast"] = "broadcast";
    BroadcastType["Unknown"] = "unknown";
})(BroadcastType || (BroadcastType = {}));
export class ProtocolMessage {
    type = BroadcastType.Unknown;
}
export class Broadcast extends ProtocolMessage {
    type = BroadcastType.Broadcast;
    payload;
    constructor(payload) {
        super();
        this.payload = payload;
    }
}
export class BroadcastRequest extends ProtocolMessage {
    type = BroadcastType.BroadcastRequest;
    payload;
    constructor(payload) {
        super();
        this.payload = payload;
    }
}
export class BroadcastResponse extends ProtocolMessage {
    type = BroadcastType.BroadcastResponse;
    payload;
    constructor(payload) {
        super();
        this.payload = payload;
    }
}
export class BroadcastProtocolImpl {
    channel;
    constructor(channel) {
        this.channel = channel;
    }
    onMessage() {
        return this.channel.onMessage();
    }
    broadcast(payload) {
        this.channel.postMessage(new Broadcast(payload));
    }
    broadcastRequest(payload) {
        this.channel.postMessage(new BroadcastRequest(payload));
    }
    broadcastResponse(payload) {
        this.channel.postMessage(new BroadcastResponse(payload));
    }
}
export class BroadcastChannelImpl {
    channel;
    constructor(channelName) {
        this.channel = new BroadcastChannel(channelName);
    }
    onMessage() {
        return fromEvent(this.channel, "message").pipe(map(event => event.data));
    }
    postMessage(message) {
        this.channel.postMessage(message);
    }
}
export class CrossWindowChannelImpl {
    src;
    dest;
    constructor(src, dest) {
        this.src = src;
        this.dest = dest;
    }
    onMessage() {
        return fromEvent(this.src, "message").pipe(map(event => event.data));
    }
    postMessage(message) {
        this.dest.postMessage(message, "*");
    }
}
