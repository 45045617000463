import { filter, fromEvent } from "rxjs";
export function getWindowMessageObserver(origin = null) {
    const observable = fromEvent(window, "message");
    return observable.pipe(filter(event => origin ? event.origin === origin : true));
}
export function promisify(target, eventName) {
    return new Promise(resolve => {
        const listener = (event) => {
            target.removeEventListener(eventName, listener);
            resolve(event);
        };
        target.addEventListener(eventName, listener);
    });
}
export function waitForDocumentLoad() {
    if (document.readyState === "complete") {
        return;
    }
    return promisify(window, "load");
}
