import { filter, firstValueFrom, map, timeout } from "rxjs";
import { BroadcastType } from "./BroadcastProtocol";
import { v4 as uuidv4 } from "uuid";
var ConversationEventType;
(function (ConversationEventType) {
    ConversationEventType["ConversationStarted"] = "conversation-started";
    ConversationEventType["ConversationCompleted"] = "conversation-completed";
})(ConversationEventType || (ConversationEventType = {}));
class ConversationLifecyclePayload {
    event;
    conversationName;
    type = "ConversationLifecylePayload";
    constructor(event, conversationName) {
        this.event = event;
        this.conversationName = conversationName;
    }
}
class GetConversationsRequestPayload {
    type = "GetConversationRequestPayload";
    id;
    constructor(id) {
        this.id = id;
    }
}
class GetConversationsResponsePayload {
    type = "GetConversationResponsePayload";
    conversations;
    id;
    constructor(conversations, id) {
        this.conversations = conversations;
        this.id = id;
    }
}
export class ConversationBroadcastServer {
    getConversations;
    protocol;
    constructor(getConversations, protocol) {
        this.getConversations = getConversations;
        this.protocol = protocol;
        protocol.onMessage().pipe(filter(v => v.type == BroadcastType.BroadcastRequest && v.payload.type == "GetConversationRequestPayload"), map(v => v.payload)).subscribe(async ({ id }) => {
            const conversations = await getConversations();
            protocol.broadcastResponse(new GetConversationsResponsePayload(conversations, id));
        });
    }
    sendConversationStarted(conversationName) {
        this.protocol.broadcast(new ConversationLifecyclePayload(ConversationEventType.ConversationStarted, conversationName));
    }
    sendConversationCompleted(conversationName) {
        this.protocol.broadcast(new ConversationLifecyclePayload(ConversationEventType.ConversationCompleted, conversationName));
    }
}
export class ConversationBroadcastClient {
    protocol;
    constructor(protocol) {
        this.protocol = protocol;
    }
    getConversations() {
        const id = uuidv4();
        this.protocol.broadcastRequest(new GetConversationsRequestPayload(id));
        const resp = this.protocol.onMessage()
            .pipe(filter(v => v.type == BroadcastType.BroadcastResponse && v.payload.type == "GetConversationResponsePayload"), map(v => v.payload), timeout(2000));
        return firstValueFrom(resp);
    }
    onConversationStarted() {
        return this.protocol.onMessage().pipe(filter(v => v.type == BroadcastType.Broadcast && v.payload.type == "ConversationLifecylePayload"), map(v => v.payload), filter(v => v.event == ConversationEventType.ConversationStarted));
    }
    onConversationCompleted() {
        return this.protocol.onMessage().pipe(filter(v => v.type == BroadcastType.Broadcast && v.payload.type == "ConversationLifecylePayload"), map(v => v.payload), filter(v => v.event == ConversationEventType.ConversationCompleted));
    }
}
